<template>
    <div class="page login">
        <div class="top">
            <div class="title">我们需要验证您的登录信息</div>
            <div class="desc">请输入手机号码领取</div>
        </div>
        <div class="form">
            <div class="group">
                <div class="label">手机号码</div>
                <div class="input">
                    <input type="number" maxlength="11" placeholder="请输入您的手机号码" v-model="phone"/>
                </div>
            </div>
            <div class="group">
                <div class="label">短信验证码</div>
                <div class="input">
                    <input type="number" maxlength="6" placeholder="请输入手机验证码" v-model="captcha"/>
                    <div class="verifyButton" @click="onGetSmsCode">{{smsText}}</div>
                </div>
            </div>
            <div class="button" @click="onSubmit">登录</div>
        </div>
        <div class="bottom">
            <BottomCom/>
        </div>
    </div>
</template>

<script>
    import {Toast} from 'vant';
    import _ from 'lodash';

    import BottomCom from "../components/BottomCom";
    import {phoneLogin, sendSmsCode} from "../api/data";

    export default {
        name: "Login",
        components: {BottomCom},
        data: function () {
            return {
                phone: '',
                captcha: '',
                smsText: "发送验证码",
                smsStatus: false,
            }
        },
        methods: {
            async onGetSmsCode() {
                if (this.smsStatus) {
                    return false;
                }
                if (this.phone == null || this.phone == '') {
                    Toast.fail("请先输入手机号");
                    return false;
                }

                let value = {};
                value.phone = this.phone;
                let res = await sendSmsCode(value);
                if (_.toInteger(res.code) == 1) {
                    Toast.success("发送成功");
                    let timeCount = 120;
                    let timer = setInterval(() => {
                        timeCount--;
                        if (timeCount) {
                            this.smsStatus = true;
                            this.smsText = `${timeCount} 秒后获取`
                        } else {
                            this.smsStatus = false;
                            this.smsText = '发送验证码'
                            clearInterval(timer);
                        }
                    }, 1000)
                    return false;
                } else {
                    Toast.fail(res.msg);
                    return false;
                }
            },
            async onSubmit() {
                if (this.phone == null || this.phone == '') {
                    Toast.fail("请先输入手机号");
                    return false;
                }
                if (this.captcha == null || this.captcha == '') {
                    Toast.fail("请先输入短信验证码");
                    return false;
                }
                let that = this;
                let param = {};
                param.phone = this.phone;
                param.sms = this.captcha;
                let res = await phoneLogin(param);
                // let res = {code: 1, msg: "请求成功", time: "1625374806", data: {token: "0f890136-aa18-4ad7-8774-a74fcfc9495f", status: 0}};
                if (_.toInteger(res.code) == 1) {
                    localStorage.setItem("token", res.data.token);
                    if (_.toInteger(res.data.status) === 0) {
                        await that.$router.push('/Convert')
                    } else {
                        await that.$router.push('/')
                    }
                } else {
                    Toast.fail(res.msg);
                }
            },
        }
    }
</script>

<style scoped>
    .login {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
    }

    .login .top {
        width: 630px;
        margin-top: 51px;
        margin-left: 16px;
    }

    .login .top .title {
        font-size: 36px;
        font-weight: 800;
        line-height: 50px;
        color: #A05923;
    }

    .login .top .desc {
        margin-top: 10px;
        font-size: 28px;
        font-weight: 400;
        line-height: 40px;
        color: #A05923;
    }

    .login .form {
        width: 630px;
        height: 529px;
        margin-top: 37px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 30px;
    }

    .login .form .group {
        width: 570px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 48px;
    }

    .login .form .group .label {
        width: 570px;
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        color: #141E2A;
    }

    .login .form .group .input {
        width: 570px;
        margin-top: 24px;
        margin-bottom: 17px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .login .form .group .input input {
        flex: 1;
        border: 0;
    }


    .login .form .group .input .verifyButton {
        font-size: 26px;
        font-weight: bold;
        color: #A05923;
    }

    .login .form .group .input input::-webkit-input-placeholder,
    .login .form .group .input input::-moz-placeholder,
    .login .form .group .input input:-ms-input-placeholder {
        /* WebKit browsers */
        color: #C7C7C7;
        font-size: 30px;
    }

    .login .form .button {
        height: 80px;
        background: linear-gradient(107deg, #3C3F45 0%, #1F2226 100%);
        font-size: 26px;
        font-weight: bold;
        border-radius: 16px;
        color: #EFDAC0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login .bottom {
        width: 690px;
        height: 533px;
        background: url("../assets/body_bg.png") no-repeat right bottom;
        background-size: auto 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
